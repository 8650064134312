<template>
  <SectionTitle
    icon="icon-services"
    h2="További szolgáltatásaink"
    h2small="Ismerje meg egyéb szolgáltatásainkat!"
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div v-if="actuale != 1" class="col-lg-4 mt-5 text-center">
          <router-link
            @click="hidden = !hidden"
            class="section-link"
            to="/szolgaltatasaink/landing-oldal-keszites"
          >
            <div class="col-6 col-lg-12 m-auto mb-3 mb-lg-0">
              <img
                src="@/assets/img/all/img-box-services-landing-page-left.png"
                alt=""
                class="img-fluid h2-icon h2-icon-top"
              />
            </div>
            <h2 class="text-center">
              Bemutatkozó (landing) oldal micro- és kisvállalkozásoknak
            </h2>
            <div class="d-grid col-lg-8 m-auto">
              <div class="btn btn-rainbow pt-4 pb-4">
                <span>részletek</span>
              </div>
            </div>
          </router-link>
        </div>
        <div v-if="actuale != 2" class="col-lg-4 mt-5 text-center">
          <router-link
            @click="hidden = !hidden"
            class="section-link"
            to="/szolgaltatasaink/statikus-es-dinamikus-banner-keszites"
          >
            <div class="col-6 col-lg-12 m-auto mb-3 mb-lg-0">
              <img
                src="@/assets/img/all/img-box-services-banner-left.png"
                alt=""
                class="img-fluid h2-icon h2-icon-top"
              />
            </div>
            <h2 class="text-center">Statikus- és dinamikus banner készítés</h2>
            <div class="d-grid col-lg-8 m-auto">
              <div class="btn btn-rainbow pt-4 pb-4">
                <span>részletek</span>
              </div>
            </div>
          </router-link>
        </div>
        <div v-if="actuale != 3" class="col-lg-4 mt-5 text-center">
          <router-link
            @click="hidden = !hidden"
            class="section-link"
            to="/szolgaltatasaink/support-szolgaltatas"
          >
            <div class="col-6 col-lg-12 m-auto mb-3 mb-lg-0">
              <img
                src="@/assets/img/all/img-box-services-support-left.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <h2 class="text-center">Support szolgáltatás partnereinknek</h2>
            <div class="d-grid col-lg-8 m-auto">
              <div class="btn btn-rainbow pt-4 pb-4">
                <span>részletek</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {};
  },

  props: {
    actuale: Number,
  },
};
</script>
