<template>
  <section class="section section-hero">
    <div class="section-hero-bg">
      <SectionTitle
        icon="icon-services"
        h2="Évről-évre halogatta a döntést és így cégének még mindig nincs weboldala? <br class='d-none d-lg-block'> Új szolgáltatást vagy terméket kíván bevezetni?"
        h2small="Kezdje a 2021-es évet új lendülettel, landoljon minél több érdeklődő az oldalain."
        createdate=""
      />
    </div>
    <div class="section section-hero-body section-hero-services">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 order-2 order-lg-1">
            <small class="h2-small">
              Akár pár nap alatt elindulhat modern, gyorsan betöltődő, mobilon
              is jól használható termékeit, szolgáltatásait vagy akár önmagát,
              vállalkozását bemutató weboldala.
            </small>

            <div class="box-contents">
              <h3 class="d-none d-lg-block">// Landing oldal</h3>
              <div id="bovebben-a-landing-oldalakrol"></div>
              <br />

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#bovebben-a-landing-oldalakrol"
              >
                <span># Bővebben a landing oldalakról</span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#mire-erdemes-figyelni"
              >
                <span>
                  <em class="d-none d-xxl-inline-block">
                    # Mire érdemes figyelni egy landing oldal készítésénél?
                  </em>
                  <em class="d-inline-block d-xxl-none">
                    # Mire kell figyelni egy landing oldalnál?
                  </em>
                </span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#technologiak"
              >
                <span> # Technológiák, amiket használunk </span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link btn-link-cta"
                to="#megrendeles"
              >
                <span># A szolgáltatás megrendelése</span>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-6 order-1 order-lg-2">
            <div class="col-6 col-lg-12 m-auto mb-3 mb-lg-0">
              <img
                src="@/assets/img/all/img-box-services-banner-left.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Bővebben a landing oldalakról"
    h2small="Alapfogalmak, definíciók, mire jó ez az egész?"
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3>// Mi is az a landing oldal?</h3>
          <p class="lead">
            Az online marketingben a landing oldal - melyet hívnak még
            "céloldalnak", "érkeztető oldalnak", "statikus oldalnak" is - az
            esetek döntő többségében egyetlen weboldal, amely a konverzió
            növelésére szolgál (ez legtöbbször adatszerzés vagy értékesítés)
            jött létre.
          </p>
          <div class="framed">
            <p class="lead">
              <strong>Konverzió</strong> A konverzió jelentése egy adott üzleti
              cél, eredmény megvalósulása. Ez a cél lehet az oldalon történő
              regisztráció, egy adott termék megrendelése, egy űrlap vagy
              kérdőív kitöltése, hírlevélre való feliratkozás, vagy bármi egyéb,
              ami ezekhez hasonló, konkrét cselekvést feltételez a felhasználó
              részéről. Ahogyan a nevéből is sejthető, az oldal egyszerű
              látogatója ilyenkor interakcióba lép üzleti célunkkal és
              vásárlóvá, aktív résztvevővé konvertálódik. A konverzió ebből
              adódóan egy optimalizálható és mérhető folyamat.
            </p>
          </div>
          <h3>// Hogyan kerül a látogató a landing oldalra?</h3>
          <p class="lead">
            A landing oldalra keresési eredményre kattintással vagy direkt
            linkkel lehet eljutni. Gyakran összekapcsolják a közösségi médiával,
            e-mail kampányokkal, keresőmotor marketing kampányokkal, promóciós
            cikkekkel a hirdetések hatékonyságának növelése érdekében.
          </p>
          <h3>// Mi a célja egy landing oldalnak?</h3>
          <p class="lead">
            A céloldal általában olyan irányított értékesítés csatorna, amely a
            hirdetés, a keresési eredmény vagy a direkt link logikai kibővítése.
            A céloldal lehet egy microsite vagy egy cégoldal része is. Az
            érkeztető oldal általános célja a webhely látogatóinak értékesítéssé
            vagy potenciális ügyfelekké alakítása.
          </p>
          <h3>// Hogyan működik a céloldal?</h3>
          <p class="lead">
            A céloldal tartalmaz valamilyen módszert, amellyel a látogató
            kapcsolatba léphet a céggel, általában kérdőívet egy egyszerű
            kapcsolatfelvételi űrlapot esetleg email címet és/vagy
            telefonszámot. Ha eladásra van szükség, akkor a céloldalon általában
            megtalálható egy link, amelyre a látogató kattinthat, majd elküldi
            őket egy bevásárlókosárba vagy egy pénztár területére. A linkelt URL
            által generált tevékenység elemzésével a marketingszakemberek az
            átkattintási arányokat és a konverziós arányokat használhatják a
            reklám sikerének meghatározásához.
          </p>
          <h3>// Miért van szükség landing oldalra?</h3>
          <p class="lead">
            A landing oldal arra szolgál, hogy az oldalra érkezőt egy jól
            meghatározott cél felé vezesse (ügyféladatok, rendelés) miközben
            folyamatosan - a nyújtott információkkal, ajándékkal - bizalmat épít
            fel a látogatóval, hogy meggyőzze és további lépésre sarkallja.
          </p>
          <h3>// Mitől fog hatékonyan működni egy landing oldal?</h3>
          <p class="lead">
            1. <strong>Teljes összhang szükséges:</strong> a landing oldalra
            mutató reklám és a tartalom között. Ha azt igérjük, hogy az
            érdeklődő 5 tippet talál az autószerelésről, akkor tényleg találjon
            is 5 tippet az oldalon.
          </p>
          <p class="lead">
            2. <strong>Megfelelő grafikai elemek használata:</strong> a landing
            oldalra érkezéskor a látogatónak már van sejtése arról, hogy mit fog
            kapni, viszont ez még kevés ahhoz, hogy ezért megadja adatait vagy
            vásároljon. Így meg kell győzni őt arról, hogy mindenképpen szüksége
            van a reklámozott termékre vagy szolgáltatásra. Egy jól elhelyezett
            infografika, izléses termékfotó, megfelelő méretű gomb, a
            felhasználó végigvezetése az oldalon segíti, hogy a figyelem
            folyamatosan fókuszban maradjon. Fontos, hogy az oldal reszponzív
            legyen, hogy minden céleszközön megfelelően jelenjen meg.
          </p>

          <div class="framed">
            <p class="lead">
              <strong>Responsive Web Design (RWD)</strong>
              A reszponzív weboldal tervezése és kivitelezése is úgy készül,
              hogy optimális megjelenést biztosítson (könnyű olvashatóság,
              egyszerű navigáció a lehető legkevesebb átméretezéssel és
              görgetéssel) a legkülönfélébb eszközökön (mobiltelefon, táblagép,
              monitor) Egy reszponzív elv alapján tervezett oldal tökéletesen
              igazodik a megjelenítő eszközhöz, mindezt rugalmas felépítéssel,
              flexibilis képekkel.
            </p>
          </div>

          <p class="lead">
            3. <strong>Szöveg, szöveg, szöveg:</strong> a landing oldal
            legfontosabb része a szöveges tartalom. Nemcsak a jól bevált
            szövegezési fordulatok használata, a jól elhelyezett kulcsszavak,
            hanem a minőségi tartalom adja el a terméket vagy a szolgáltatást.
            Hiszen ezek segítségével nemcsak a keresőmotorok, hanem a közösségi
            megosztások is sok új látogatót irányíthatnak a landing oldalra. A
            szöveges tartalom alapvető célja, hogy lényegre törő, tömör, mégis
            beszédes módon, megfelelő mennyiségű és minőségű információt adjon
            át a látogatók számára, mely képes meggyőzni őket arról, hogy
            rákattintson a Call To Action gombunkra.
          </p>
          <div class="framed">
            <p class="lead">
              <strong>CTA (Call To Action)</strong> A cselekvésre ösztönzés
              minden olyan marketing eszköz összessége, amelynek célja az
              azonnali reagálás ösztönzése vagy az azonnali eladás ösztönzése. A
              CTA leggyakrabban reklámüzenetekbe vagy weboldalakba beépíthető
              szavak vagy kifejezések használatára utal, amelyek a látogatókat
              meghatározott módon cselekedni kényszerítik. Például: hívjon
              most!, tudjon meg többet!, regisztráljon az ingyenes mintáért! Az
              erőteljes cselekvésre ösztönzés kulcsa az, hogy kényszerítő okokat
              adjon a látogatónak az azonnali vásárlásra, ahelyett, hogy
              elhalasztanák a vásárlási döntéseket. Például: Még 2 napig, Egyet
              fizet, kettőt kap!
            </p>
          </div>
          <div id="mire-erdemes-figyelni"></div>
          <p class="lead">
            4. <strong>Fejlesztés</strong> ha a landing oldal elkészült, még
            csak félig vagyunk meg a munkával. Ha szeretnénk maximalizálni az
            eredményeinket, érdemes több változatot készítenünk és A-B
            teszteléssel kiválasztani a legmegfelelőbb variációt. Arra azonban
            figyeljünk, hogy egyszerre csak egy dolgon változtassunk. Ellenkező
            esetben nem fogjuk tudni, melyik változtatásnak köszönhetjük, ha
            változik az eredményünk.
          </p>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Mire érdemes figyelni egy landing oldal készítésénél?"
    h2small="Bármilyen egyszerűnek is hangzik, az alábbi szempontok alapvetően befolyásolják a landing oldal kialakítását! Minden apróságnak tűnő információ javíthat abban, hogy az oldal sikeres legyen!"
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5 mt-5 text-center">
          <img
            src="@/assets/img/all/icon-about-company.svg"
            alt="Céginformációk"
            class="img-fluid"
          />
          <br />
          <h3 class="mt-4">// Céginformációk</h3>
          <p class="lead">
            Az olyan céges információk amelyek a vásárlók bizalmát növelik
            (díjak, elért eredmények, történelmének mérföldkövei). A cég
            sikerterméke, legfontosabb szolgáltatása, bármely egyéb olyan adat,
            amelyből ismerheti a látogató a céget.
          </p>
          <div class="mt-5">
            <img
              src="@/assets/img/all/icon-about-target-group.svg"
              alt="Minden célhoz egy landing oldal"
              class="img-fluid"
            />
            <br />
            <h3 class="mt-4">// Célcsoport</h3>
            <p class="lead">
              Meghatározott közönséghez szóljon az érkeztető oldal. A célcsoport
              problémáira, vágyaira, félelmeire nyújtson megoldást. Az oldal
              arculata is igazodjon ehhez.
            </p>
          </div>
          <div class="mt-5">
            <img
              src="@/assets/img/all/icon-about-mennyiert.svg"
              alt="Külső"
              class="img-fluid"
            />

            <br />
            <h3 class="mt-4">// Büdzsé</h3>
            <p class="lead">
              Mindenképpen legyen elképzelés arról, hogy mekkora pénzösszegből
              lehet gazdálkodni. Természetesen a terv változhat pozitív és
              negatív irányban az igényeknek megfelelően.
            </p>
          </div>
        </div>
        <div class="d-none d-lg-block col-lg-2 mt-5 pt-5">
          <img
            src="@/assets/img/all/bg-services-landing-page-line.svg"
            alt="Mit csinálunk?"
            class="img-fluid"
          />
        </div>

        <div class="col-12 col-lg-5 mt-5 text-center">
          <img
            src="@/assets/img/all/icon-about-mit.svg"
            alt="Minden célhoz egy landing oldal"
            class="img-fluid"
          />
          <br />
          <h3 class="mt-4">// Cél</h3>
          <p class="lead">
            Egy oldalnak 1 célja legyen (termék vagy szolgáltatás értékesítés,
            e-mail listára feliratkoztatás, online jelenlét megalapozása,
            karrier lehetőség). Másik cél másik landing oldal.
          </p>

          <div class="mt-5 pt-5">
            <img
              src="@/assets/img/all/icon-about-competition.svg"
              alt="Konkurencia?"
              class="img-fluid"
            />
            <br />
            <h3 class="mt-4">// Konkurencia</h3>
            <p class="lead">
              Kik a versenytársak és milyen az online jelenlétük? Mit csináljak
              irígylésre méltőan, mit csinálnak rosszul? Nem lemásolni kell
              őket, hanem a jóból még jobbat létrehozni.
            </p>
          </div>

          <div class="mt-5 pt-5">
            <img
              src="@/assets/img/all/icon-about-design.svg"
              alt="Külső"
              class="img-fluid"
            />
            <br />
            <h3 class="mt-4">// Külső</h3>
            <div id="technologiak"></div>
            <p class="lead">
              A landing oldal hatékonyságát a célcsoportnak megfelelő külsővel
              (design) lehet tovább növelni. A design célja, hogy támogassa (és
              ne elnyomja) az oldal célját. A külsővel kapcsolatos lényeges
              elvárás még az is, hogy minden eszközön (telefon, táblagép,
              monitor) is a lehetőségekhez mérten tökéletesen közvetítse az
              üzenetet.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Technológiák, amiket használunk"
    h2small=""
    createdate=""
  />

  <section class="section section-body section-body-icons">
    <div id="megrendeles"></div>
    <div class="container">
      <div class="row">
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-html5.svg" alt="HTML5" />
          <h3 class="mt-2"># HTML</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-css3.svg" alt="CSS3" />
          <h3 class="mt-2"># CSS</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-scss.svg" alt="SCSS" />
          <h3 class="mt-2"># SCSS</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-javascript.svg" alt="JavaScript" />
          <h3 class="mt-2"># JavaScript</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-bootstrap5.svg" alt="Bootstrap 5" />
          <h3 class="mt-2"># Bootstrap</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-vuejs.svg" alt="VUE.JS 3" />
          <h3 class="mt-2"># VUE.JS</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-sketch.svg" alt="Sketch" />
          <h3 class="mt-2"># Sketch</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-photoshop.svg" alt="Photoshop" />
          <h3 class="mt-2"># Photoshop</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-firebase.svg" alt="Firebase" />
          <h3 class="mt-2"># Firebase</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-mysql.svg" alt="Mysql" />
          <h3 class="mt-2"># Mysql</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-php.svg" alt="Php" />
          <h3 class="mt-2"># php</h3>
        </div>
        <div class="col-4 col-lg-2 mt-3 text-center">
          <img
            src="@/assets/img/all/icon-vscode.svg"
            alt="Visual Studio Code"
          />
          <h3 class="mt-2"># VS Code</h3>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Landing oldal készítési <br class='d-none d-lg-block'> irányárak, megrendelés"
    h2small="Kedvező árakkal, egyszerű fizetési megoldásokkal rendelheti meg <br class='d-none d-lg-block'> landing oldal szolgáltatásunkat"
    createdate=""
  />

  <section v-if="sent" class="section section-price-box mt-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="lead text-center">
            Köszönjük a megkeresést, hamarosan jelentkezünk!
          </p>
        </div>

        <div class="col-12 text-center">
          <router-link
            @click="hidden = !hidden"
            class="btn pt-4 pb-4 mt-3 mb-5"
            to="/szolgaltatasaink/landing-oldal-keszites"
          >
            <span class="ps-5 pe-5">Új megrendelés? </span>
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section v-if="!sent" class="section section-price-box mt-5 mb-5">
    <div class="container">
      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice30"
            data-bs-target="#collapseprice30"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Landing oldalak száma (db)</h5>
                <p class="collapse show" id="collapseprice30">
                  Állítsa be a szükséges darabszámot (minden különböző
                  tartalommal rendelkező oldal új oldalnak számít)!
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center w-100"
          >
            <h4 class="w-75">
              <input
                type="range"
                class="form-range"
                min="1"
                max="20"
                id="range"
                v-model="range"
                v-if="!showform"
              />
              {{ range }}
              <span>db</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice50"
            data-bs-target="#collapseprice50"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />

                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Grafikai terv készítése (igen/nem)</h5>
                <p class="collapse show" id="collapseprice50">
                  Ha van már elkészült grafikai terve a landing oldal(ak)ról
                  akkor válassza a NEM lehetőséget. A grafikai terveket külön
                  díj ellenében készítjük.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioDesign"
                id="option0-design"
                value="false"
                v-model="design"
              />
              <label class="btn pt-3 pb-3" for="option0-design">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> nem </span>
              </label>
            </div>
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioDesign"
                id="option1-design"
                value="true"
                v-model="design"
              />
              <label class="btn pt-3 pb-3" for="option1-design">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> igen </span>
              </label>
            </div>

            <div v-if="showform">
              <h4 v-if="!design">nem</h4>
              <h4 v-if="design">igen</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice60"
            data-bs-target="#collapseprice60"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Kedvezmény kupon (%)</h5>
                <p class="collapse show" id="collapseprice60">
                  A csomagnak megfelelő kedvezmény (10%, 20%, 30%) kupon 90
                  napon belül használható fel újabb termék vagy szolgáltatás
                  megrendelése esetén.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>
              {{ coupon }}
              <span>%</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice90"
            data-bs-target="#collapseprice90"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />

                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Online konzultáció (igen/nem)</h5>
                <p class="collapse show" id="collapseprice90">
                  Ha úgy érzi, hogy szüksége van bővebb tájékoztatásra akkor a
                  válaszott online platformon bővebb tájékoztatást nyújtunk,
                  külön díj ellenében (7620 Ft), a landing oldal készítéssel
                  kapcsolatban (30perc).
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioOnline"
                id="option0-online"
                value="false"
                v-model="online"
              />
              <label class="btn pt-3 pb-3" for="option0-online">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> nem </span>
              </label>
            </div>
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioOnline"
                id="option1-online"
                value="true"
                v-model="online"
              />
              <label class="btn pt-3 pb-3" for="option1-online">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> igen </span>
              </label>
            </div>

            <div v-if="showform">
              <h4 v-if="!online">nem</h4>
              <h4 v-if="online">igen</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice100"
            data-bs-target="#collapseprice100"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Irányár (Ft)</h5>
                <p class="collapse show" id="collapseprice100">
                  Az ár tájékoztató jellegű, függ a szöveges anyag
                  mennyiségétől, a használandó grafikai elemektől, a módosítások
                  számától, az oldal(ak) bonyolultságától! Az árak a 27%-s áfát
                  tartalmazzák.
                  <br />
                  <strong>
                    A végleges ár meghatározása a részletek megismerése után
                    történik.
                  </strong>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>Irányár: {{ totalpricecalculate }} Ft</h4>
          </div>
        </div>
      </div>

      <div v-if="!showform" class="row equal-cols">
        <div class="col price-col price-col-1"></div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <button @click="showform = true" class="btn btn-send pt-4 pb-4">
              <span class="ps-4 pe-4 text-center">
                Landing oldal készítés megrendelése
              </span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="showform" class="row">
        <div class="d-none d-lg-block col-lg-2 price-col-1"></div>
        <div class="col-12 col-lg-8 price-col-1">
          <Form
            v-if="!sent"
            @submit="sendForm"
            :validation-schema="schema"
            v-slot="{ errors }"
            class="contact-us-form"
          >
            <div class="form-group">
              <label for="name">// Cégnév, kapcsolattartó</label>
              <Field hidden name="pages" :value="this.range" />
              <Field hidden name="design" :value="this.design" />
              <Field hidden name="coupon" :value="this.coupon" />
              <Field hidden name="online" :value="this.online" />
              <Field hidden name="totalprice" :value="totalpricecalculate" />
              <Field
                name="name"
                as="input"
                placeholder="Kérjük, adja meg cégnevét és a kapcsolattartó nevét!"
                class="form-control"
                :class="{ 'is-invalid': errors.name }"
              />
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>

            <div class="form-group">
              <label for="email">// Email cím</label>
              <Field
                name="email"
                as="input"
                placeholder="Kérjük, adja meg email címét!"
                class="form-control"
                :class="{ 'is-invalid': errors.email }"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group">
              <label for="phone_number">// Telefonszám</label>
              <Field
                name="phone_number"
                as="input"
                placeholder="Kérjük, adja meg telefonszámát!"
                class="form-control"
                :class="{ 'is-invalid': errors.phone_number }"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group">
              <label for="when">// Mikor keressük telefonon?</label>
              <Field
                name="when"
                as="select"
                class="form-control"
                :class="{ 'is-invalid': errors.service }"
              >
                <option value="" disabled selected>
                  Kérjük, adja meg mikor kereshetjük telefonon?
                </option>
                <option value="9-12">9-12h</option>
                <option value="12-16">12-16h</option>
                <option value="Ne keressenek">
                  Nem kérek telefonos hívást
                </option>
              </Field>
              <div class="invalid-feedback">{{ errors.service }}</div>
            </div>

            <div class="form-group">
              <label for="message">// Üzenet</label>
              <Field
                name="message"
                as="textarea"
                placeholder="Üzenet"
                class="form-control form-message"
                value=""
                :class="{ 'is-invalid': errors.message }"
              />
              <div class="invalid-feedback">{{ errors.message }}</div>
            </div>

            <div class="form-check">
              <Field
                name="accept"
                type="checkbox"
                id="accept"
                value="true"
                class="form-check-input custom-control-input"
                :class="{ 'is-invalid': errors.accept }"
              />
              <label for="accept" class="form-check-label custom-control-label">
                Megismertem és elfogadom az <br />
                <router-link
                  @click="hidden = !hidden"
                  class="btn-privacy-link"
                  to="/adatvedelmi-tajekoztato"
                >
                  <span>adatvédelmi nyilatkozatot</span>
                </router-link>
              </label>
            </div>

            <div class="form-group text-center mt-5">
              <div class="d-grid col-lg-8 m-auto">
                <button type="submit" class="btn pt-4 pb-4 mb-2">
                  <span>Üzenet elküldése</span>
                </button>
                Minden mező kitöltése kötelező. <br />
                A megadott adatokat csak és kizárólag a kapcsolatfelvételhez
                használjuk fel.
              </div>
            </div>
          </Form>
        </div>
        <div class="d-none d-lg-block col-lg-2 price-col-1"></div>
      </div>
    </div>
  </section>

  <SectionTovabbiSzolgaltatasaink actuale="1" />
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import SectionTovabbiSzolgaltatasaink from "../../components/SectionTovabbiSzolgaltatasaink.vue";

export default {
  components: {
    SectionTitle,
    SectionTovabbiSzolgaltatasaink,
    Form,
    Field,
  },
  data() {
    const schema = Yup.object().shape({
      name: Yup.string().required("A név kötelező!"),
      email: Yup.string()
        .required("Az email kötelező!")
        .email("Az email érvénytelen!"),
      phone_number: Yup.string().required("A telefonszám kötelező!"),
      when: Yup.string().required("Kötelező mező!"),
      budget: Yup.string().required("A tervezett költségvetés kötelező!"),
      message: Yup.string().required(
        "Legalább 100 karakter üzenet írása kötelező!"
      ),
      accept: Yup.string().required(
        "Fogadja el az adatkezelési irányelveinket!"
      ),
    });

    return {
      range: 1,
      price15: 31750,
      price610: 25400,
      price1120: 22860,
      design: false,
      online: false,
      onlineprice: 7620,
      showform: false,
      schema,
      sent: false,
    };
  },

  computed: {
    coupon() {
      let couponqty = 10;
      if (this.range > 5) couponqty = 20;
      if (this.range > 10) couponqty = 30;
      return couponqty;
    },

    totalpricecalculate() {
      let totalprice = 0;

      if (this.range <= 5) {
        totalprice = this.range * this.price15;

        if (this.design === "true") {
          totalprice *= 2.2;
        }
      }

      if (this.range > 5) {
        totalprice = this.price610 * (this.range - 5) + 5 * this.price15;

        if (this.design === "true") {
          totalprice *= 1.7;
        }
      }

      if (this.range > 10) {
        totalprice =
          this.price1120 * (this.range - 10) +
          5 * this.price15 +
          5 * this.price610;

        if (this.design === "true") {
          totalprice *= 1.4;
        }
      }

      if (this.online === "true") totalprice += this.onlineprice;
      if (this.source === "true") totalprice += this.sourceprice;

      return totalprice;
    },
  },

  methods: {
    sendForm(e) {
      axios
        .post("https://arda.hu/api/send_form_email_landing.php", {
          name: e.name,
          email: e.email,
          phone: e.phone,
          pages: e.pages,
          design: e.design,
          coupon: e.coupon,
          online: e.online,
          message: e.message,
          accept: e.accept,
          totalprice: e.totalprice,
        })
        .then(() => {
          this.sent = true;
        });
    },

    onReset() {
      this.sent = false;
    },
  },
};
</script>
